:root {
  --color: yellow;
  --shadow: rgba(255, 215, 0, 0.6);
  --glare: rgba(255, 255, 255, 0.75);
  --star-color: rgba(255, 215, 0, 0.85);
  --font-size: 3rem;
  --transition: 0.2s;
}

.sparkle-button {
  --padding: 10px;
  padding: var(--padding);
  border-radius: 20px;
  text-decoration: none;
  color: transparent;
  position: relative;
  transition: background 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sparkle-button span {
  display: inline-block;
  font-size: calc(var(--font-size) * 1.5);
  font-weight: 700;
  color: var(--color);
  position: relative;
  transition: all 0.2s;
  text-shadow: 0 1px 1px var(--shadow), 0 2px 2px var(--shadow),
    0 3px 3px var(--shadow), 0 4px 4px var(--shadow), 0 5px 5px var(--shadow),
    0 6px 6px var(--shadow);
}

/* Effet de scintillement constant */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes text-glow {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

.sparkle-button span:last-of-type {
  position: absolute;
  inset: var(--padding);
  background: linear-gradient(
    108deg,
    transparent 0 55%,
    var(--glare) 55% 60%,
    transparent 60% 70%,
    var(--glare) 70% 85%,
    transparent 85%
  );
  -webkit-background-clip: text;
  color: transparent;
  z-index: 2;
  text-shadow: none;
  background-size: 400% 100%;
  animation: shimmer 10s linear infinite, text-glow 1.5s ease-in-out infinite;
}

.sparkle-button svg {
  position: absolute;
  z-index: 3;
  width: calc(var(--font-size) * 0.7);
  aspect-ratio: 1;
  fill: var(--star-color);
}

/* Animation de scintillement des étoiles */
@keyframes sparkle {
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.sparkle-button:hover svg {
  animation: sparkle 0.75s ease-in-out both infinite;
}

/* Positions des scintillements */
.sparkle-1 {
  top: 20%;
  left: 0;
  transform: scale(0);
}
.sparkle-2 {
  top: 80%;
  left: 15%;
  transform: scale(0);
}
.sparkle-3 {
  top: 40%;
  left: 45%;
  transform: scale(0);
}
.sparkle-4 {
  top: 60%;
  left: 75%;
  transform: scale(0);
}
.sparkle-5 {
  top: 30%;
  left: 100%;
  transform: scale(0);
}

.sparkle-button:hover .sparkle-1 {
  animation-delay: 0.1s;
}
.sparkle-button:hover .sparkle-2 {
  animation-delay: 0.2s;
}
.sparkle-button:hover .sparkle-3 {
  animation-delay: 0.3s;
}
.sparkle-button:hover .sparkle-4 {
  animation-delay: 0.4s;
}
.sparkle-button:hover .sparkle-5 {
  animation-delay: 0.5s;
}
